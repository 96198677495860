import React, { useState } from "react";
import { Link } from "react-scroll";
import { Nav, NavDropdown, Container } from "react-bootstrap";
import NavData from "../../Data/NavData.json";
import useWindowWidth from "../Utilities/WindowWidth";

function Navigation() {
  const width = useWindowWidth(),
    [isOpen, setOpen] = useState(false),
    CONTENT = NavData.content,
    CTA = NavData.cta;

  let delayLink = 0;
  if (width <= 1200) {
    delayLink = 800;
  }

  return (
    <React.Fragment>
      {width <= 992 ? (
        <div className={`hamburger__wrapper ${isOpen ? "isOpen" : ""}`}>
          <div className="hamburger">
            <div
              className={`hamburger__icon ${isOpen ? "isOpen" : ""}`}
              onClick={() => {
                setOpen(!isOpen);
              }}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div className="overlay">
            {isOpen ? (
              <Container>
                <Nav className="hamburger__menu-list">
                  {/* <NavDropdown title={CONTENT[0].title} id="nav-dropdown"> */}
                  <Nav.Item>
                    <span className="title">{CONTENT[0].title}</span>
                  </Nav.Item>
                  <Nav.Item>
                    <Link
                      to={CONTENT[0].propertyLinkOne}
                      href={CONTENT[0].propertyLinkOne}
                      smooth={true}
                      duration={800}
                      delay={delayLink}
                      offset={-50}
                      className="menu-list--item menu-link pl-3"
                      onClick={() => {
                        setOpen(!isOpen);
                      }}
                    >
                      {CONTENT[0].propertyOne}
                    </Link>
                  </Nav.Item>
                  <Nav.Item className="pb-3">
                    <Link
                      to={CONTENT[0].propertyLinkTwo}
                      href={CONTENT[0].propertyLinkTwo}
                      smooth={true}
                      duration={800}
                      delay={delayLink}
                      offset={-50}
                      className="menu-list--item menu-link pl-3"
                      onClick={() => {
                        setOpen(!isOpen);
                      }}
                    >
                      {CONTENT[0].propertyTwo}
                    </Link>
                  </Nav.Item>
                  {/* </NavDropdown> */}
                  <Nav.Item>
                    <Link
                      to={CONTENT[0].link}
                      href={CONTENT[0].link}
                      smooth={true}
                      duration={800}
                      delay={delayLink}
                      offset={-50}
                      className="menu-list--item menu-link"
                      onClick={() => {
                        setOpen(!isOpen);
                      }}
                    >
                      {CONTENT[0].titleTwo}
                    </Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Link
                      to={CTA[0].link}
                      href={CTA[0].link}
                      smooth={true}
                      duration={800}
                      delay={delayLink}
                      className="menu-list--item menu-link"
                      onClick={() => {
                        setOpen(!isOpen);
                      }}
                    >
                      {CTA[0].buttonTitle}
                    </Link>
                  </Nav.Item>
                </Nav>
              </Container>
            ) : null}
          </div>
        </div>
      ) : (
        <Nav className="justify-content-end align-items-center">
          <NavDropdown title={CONTENT[0].title} id="nav-dropdown">
            {/* <NavDropdown.Item> */}
            <Link
              to={CONTENT[0].propertyLinkOne}
              href={CONTENT[0].propertyLinkOne}
              smooth={true}
              duration={800}
              offset={-50}
              className="menu-list--item menu-link"
            >
              {CONTENT[0].propertyOne}
            </Link>
            {/* </NavDropdown.Item>
        <NavDropdown.Item> */}
            <Link
              to={CONTENT[0].propertyLinkTwo}
              href={CONTENT[0].propertyLinkTwo}
              smooth={true}
              duration={800}
              offset={-50}
              className="menu-list--item menu-link"
            >
              {CONTENT[0].propertyTwo}
            </Link>
            {/* </NavDropdown.Item> */}
          </NavDropdown>
          <Nav.Item>
            <Link
              to={CONTENT[0].link}
              href={CONTENT[0].link}
              smooth={true}
              duration={800}
              offset={-50}
              className="menu-list--item menu-link"
            >
              {CONTENT[0].titleTwo}
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link
              to={CTA[0].link}
              href={CTA[0].link}
              smooth={true}
              duration={800}
              className="btn btn-primary"
            >
              {CTA[0].buttonTitle}
            </Link>
          </Nav.Item>
        </Nav>
      )}
    </React.Fragment>
  );
}

export default Navigation;
