import React, { useContext } from "react";
import CircleIcon from "../../Assets/icons/fifth-circle.svg";
import { motion } from "framer-motion";
import { IntersectionContext } from "./IntersectionObserver";

function CircleSvg(props) {

  const { inView } = useContext(IntersectionContext);

  let left = 0,
    right = "auto",
    top = "auto",
    bottom = 0;

  if (props.left) {
    left = props.left;
  }
  if (props.right) {
    right = props.right;
  }
  if (props.top) {
    top = props.top;
  }
  if (props.bottom) {
    bottom = props.bottom;
  }

  const styles = {
    position: "absolute",
    left: left,
    right: right,
    top: top,
    bottom: bottom,
    zIndex: -1,
  }

  const transition = {
    duration: 2.3,
  }

  const variants = {
    hidden: {
      scale: 1,
      opacity: 0,
      rotate: "540deg",
      transition,
    },
    show: {
      scale: 1,
      opacity: 1,
      rotate: "0deg",
      transition,
    }
  };

  return (
    <div className="circle-svg" style={styles}>
      <motion.div
        initial="hidden"
        animate={inView ? "show" : "hidden"}
        exit="hidden"
        variants={variants}
      >
        <img src={CircleIcon} alt="Fifth Element Spaces Circle Icon" />
      </motion.div>
    </div>
  )
}

export default CircleSvg;
