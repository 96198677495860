import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import OurPropertiesData from "../../Data/OurPropertiesData.json";

import ImageOne from "../../Assets/images/IN1A1138.jpg";
import ImageTwo from "../../Assets/images/IN1A1208.jpg";
import ImageThree from "../../Assets/images/IN1A1190.jpg";
import Properties from "./Properties";
import PropertiesData from "../../Data/PropertiesData.json";
import { motion } from "framer-motion";
import { IntersectionContext } from "../Utilities/IntersectionObserver";
import { IntersectionObserver } from "../Utilities/IntersectionObserver";

function OurPropertiesSection() {
  const CONTENT = OurPropertiesData.content,
    PROPERTIESCONTENT = PropertiesData.content,
    { inView } = useContext(IntersectionContext);

  const transition = {
    duration: 0.8,
    delay: 1.5,
  };

  const variantsLeft = {
    hidden: {
      scale: 1,
      opacity: 0,
      transition,
      y: "400px",
    },
    show: {
      scale: 1,
      opacity: 1,
      transition,
      y: 0,
    },
  };

  const variantsRight = {
    hiddenRight: {
      scale: 1,
      opacity: 0,
      y: "400px",
    },
    showRight: {
      scale: 1,
      opacity: 1,
      y: 0,
    },
  };

  return (
    <section className="our-properties section">
      <Container>
        <Row>
          <Col md={6}>
            <motion.div
              initial="hidden"
              animate={inView ? "show" : "hidden"}
              exit="hidden"
              variants={variantsLeft}
            >
              <h2>{CONTENT[0].title}</h2>
              <p>{CONTENT[0].text}</p>
              <h3>{CONTENT[0].subTitle}</h3>
              <ul>
                {CONTENT[0].list.map((item, i) => (
                  <li className="list__item list__item--tick" key={i}>
                    {item}
                  </li>
                ))}
              </ul>
            </motion.div>
          </Col>
          <Col md={6}>
            <motion.div
              initial="hiddenRight"
              animate={inView ? "showRight" : "hiddenRight"}
              exit="hiddenRight"
              transition={{ delay: 2, duration: 0.8 }}
              variants={variantsRight}
            >
              <Row>
                <Col md={8} className="column--1">
                  <img src={ImageOne} alt="Bedroom" />
                </Col>
                <Col
                  md={4}
                  className="d-flex flex-column justify-content-end column--2"
                >
                  <img src={ImageTwo} alt="Hallway with shoes" />
                </Col>
              </Row>
              <Row>
                <Col className="mt-md-3 column--3">
                  <img src={ImageThree} alt="Side table with decorations" />
                </Col>
              </Row>
            </motion.div>
          </Col>
        </Row>
      </Container>
      <IntersectionObserver>
        <Properties content={PROPERTIESCONTENT} />
      </IntersectionObserver>
    </section>
  );
}

export default OurPropertiesSection;
