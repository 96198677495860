import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import HeroData from "../../Data/HeroData.json";
import HeroImage from "../../Assets/images/banner-image.png";
import useWindowWidth from "../Utilities/WindowWidth";
import { motion } from "framer-motion";
import { IntersectionContext } from "../Utilities/IntersectionObserver";

function Hero() {
  const CONTENT = HeroData.content,
    width = useWindowWidth(),
    { inView } = useContext(IntersectionContext);

  const transition = {
    duration: .8,
    delay: .3
  }

  const variantsLeft = {
    hidden: {
      scale: 1,
      opacity: 0,
      transition,
      height: "100%",
      y: "400px"
    },
    show: {
      scale: 1,
      opacity: 1,
      transition,
      height: "100%",
      y: 0
    }
  };

  const variantsRight = {
    hidden: {
      scale: 1,
      opacity: 0,
      transition,
    },
    show: {
      scale: 1,
      opacity: 1,
      transition,
    }
  };

  return (
    <section className="hero d-flex">
      <Container fluid>
        <Row>
          <Container>
            <Row>
              <Col md={7}>
                <motion.div
                  initial="hidden"
                  animate={inView ? "show" : "hidden"}
                  exit="hidden"
                  variants={variantsLeft}
                >
                  <div className="hero__content">
                    <h1>{CONTENT[0].title}</h1>
                    <p>{CONTENT[0].text}</p>
                  </div>
                </motion.div>
              </Col>
            </Row>
          </Container>
          {width <= 768 ? null : (
            <Col md={{ span: 5, offset: 7 }}>
              <motion.div
                initial="hidden"
                animate={inView ? "show" : "hidden"}
                exit="hidden"
                variants={variantsRight}
              >
                <div className="hero__image">
                  <img src={HeroImage} alt="Fifth Element Spaces banner" />
                </div>
              </motion.div>
            </Col>
          )}
        </Row>
      </Container>
    </section>
  );
}

export default Hero;
