import React from "react";
import CarouselTestimonial from "../Utilities/Carousel/CarouselTestimonial";
import TestimonialData from "../../Data/TestimonialData.json";
import { Container } from "react-bootstrap";

function Testimonials() {
  const CONTENT = TestimonialData.content;

  return (
    <section className="testimonials">
      <Container>
        <CarouselTestimonial content={CONTENT} />
      </Container>
    </section>
  );
}

export default Testimonials;
