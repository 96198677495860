import React from "react";
import { Event } from "../../Tracking/index";

function ButtonHref(props) {
  function handleEvent(props) {
    Event("Property link Gotenant", props.propertyTitle, props.link);
  }

  return (
    <React.Fragment>
      <a
        className={props.classes}
        href={props.link}
        rel="noopener noreferrer"
        target="_blank"
        onClick={() => {
          handleEvent(props);
        }}
      >
        {props.title}
      </a>
    </React.Fragment>
  );
}

export default ButtonHref;
