import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import FooterData from "../../Data/FooterData.json";
import Logo from "../../Assets/icons/nrla.png";
import ICOLogo from "../../Assets/icons/ico.svg"
import ModalMain from "../Utilities/Modal/ModalMain";
// import TermsData from "../../Data/TermsData.json";
import PrivacyData from "../../Data/PrivacyData.json";
import ContactForm from "../Utilities/Form/ContactForm";

function Footer() {
  const date = new Date(),
    CurrentYear = date.getFullYear(),
    CONTENT = FooterData.content,
    SOCIAL = FooterData.social,
    // TERMSDATA = TermsData.content,
    PRIVACYDATA = PrivacyData.content,
    // [termsShow, setTermsShow] = useState(false),
    [privacyShow, setPrivacyShow] = useState(false);

  return (
    <footer name="#footer">
      <Container>
        <Row>
          <Col md={6}>
            <div className="footer__information">
              <h2>{CONTENT[0].title}</h2>
              <p>{CONTENT[0].text}</p>
              {SOCIAL.map((item, index) => (
                <p key={index}>
                  <a
                    href={item.link}
                    className="social__item"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img src={item.image} alt={item.alt} />
                    {item.title}
                  </a>
                </p>
              ))}
            </div>
          </Col>
          <Col md={6}>
            <ContactForm />
          </Col>
        </Row>
        <hr />
        <Row className="d-flex justify-content-between">
          <Col md={4}>
            <img className="nrla-logo" src={Logo} alt="Nrla members logo" />
            <img className="ico-logo" src={ICOLogo} alt="ICO Registered" />
          </Col>
          <Col md={5} className="text-center text-md-right">
            <p className="small">
              &copy; {CurrentYear} Fifth Element Spaces. All rights reserved.
              {" "}
              <button
                type="button"
                className="button--modal"
                data-toggle="modal"
                data-target="#privacyModal"
                onClick={() => setPrivacyShow(true)}
              >
                Privacy
              </button>
              {/* {" "} */}
              {/* — */}
              {/* {" "} */}
              {/* <button
                type="button"
                className="button--modal"
                data-toggle="modal"
                data-target="#modal__terms"
                onClick={() => setTermsShow(true)}
              >
                Terms
              </button> */}
            </p>
            <p className="small">
              Website created by{" "}
              <a
                target="_blank"
                href="https://www.skyburgerstudio.co.uk"
                rel="noreferrer noopener"
              >
                Skyburger Studio
              </a>
            </p>
          </Col>
        </Row>
      </Container>
      {/* <ModalMain
        show={termsShow}
        handleClose={() => setTermsShow(false)}
        classes={TERMSDATA[0].classes}
        title={TERMSDATA[0].title}
        body={TERMSDATA[0].body}
      /> */}
      <ModalMain
        show={privacyShow}
        handleClose={() => setPrivacyShow(false)}
        classes={PRIVACYDATA[0].classes}
        title={PRIVACYDATA[0].title}
        body={PRIVACYDATA[0].body}
      />
    </footer>
  );
}

export default Footer;
