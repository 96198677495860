import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AboutData from "../../Data/AboutData.json";
import AccordionElement from "../Utilities/AccordionElement";
import ImageOne from "../../Assets/images/IN1A1079.jpg";
import ImageTwo from "../../Assets/images/IN1A1131.jpg";
import ImageThree from "../../Assets/images/IN1A1093.jpg";
import CircleSvg from "../Utilities/CircleSvg";
import { IntersectionObserver } from "../Utilities/IntersectionObserver";
import { IntersectionContext } from "../Utilities/IntersectionObserver";
import { motion } from "framer-motion";
import useWindowWidth from "../Utilities/WindowWidth";

function About() {
  const CONTENT = AboutData.content,
    ABOUTACCORDION = AboutData.accordion,
    width = useWindowWidth(),
    { inView } = useContext(IntersectionContext);

  const transition = {
    duration: 0.8,
    delay: 1.5,
  };

  const variantsLeft = {
    hidden: {
      scale: 1,
      opacity: 0,
      transition,
      y: "400px",
    },
    show: {
      scale: 1,
      opacity: 1,
      transition,
      y: 0,
    },
  };

  const variantsRight = {
    hiddenRight: {
      scale: 1,
      opacity: 0,
      y: "400px",
    },
    showRight: {
      scale: 1,
      opacity: 1,
      y: 0,
    },
  };

  return (
    <section className="about section" id="about" name="#about">
      <Container>
        <Row>
          <Col md={7}>
            <motion.div
              initial="hidden"
              animate={inView ? "show" : "hidden"}
              exit="hidden"
              variants={variantsLeft}
            >
              <h2>{CONTENT[0].title}</h2>
              <div className="about__content">
                {CONTENT[0].text.map((paragraph, i) => (
                  <p key={i}>{paragraph}</p>
                ))}
              </div>
              <AccordionElement
                accordionID="aboutAccordion"
                content={ABOUTACCORDION}
              />
            </motion.div>
          </Col>
          <Col md={5} className="image-cols">
            <motion.div
              initial="hiddenRight"
              animate={inView ? "showRight" : "hiddenRight"}
              exit="hiddenRight"
              transition={{ delay: 2, duration: 0.8 }}
              variants={variantsRight}
            >
              <Row>
                <Col md={{ span: 10, offset: 1 }}>
                  <img src={ImageOne} alt="Plant and ornament details" />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <img src={ImageTwo} alt="Mirror bedroom" />
                </Col>
                <Col md={5} className="pl-md-3 pr-md-5">
                  <img src={ImageThree} alt="Front door" />
                </Col>
              </Row>
            </motion.div>
          </Col>
        </Row>
      </Container>
      {width <= 768 ? null : (
        <IntersectionObserver>
          <CircleSvg right="-50px" left="auto" bottom="-110px" />
        </IntersectionObserver>
      )}
    </section>
  );
}

export default About;
