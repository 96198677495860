import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ButtonHref from "../Utilities/Buttons/ButtonHref";
import CarouselImage from "../Utilities/Carousel/CarouselImage";
import Icon from "../../Assets/icons/property-icon.svg";
import CircleSvg from "../Utilities/CircleSvg";
import { IntersectionObserver } from "../Utilities/IntersectionObserver";
import useWindowWidth from "../Utilities/WindowWidth";
import { motion } from "framer-motion";
import { IntersectionContext } from "../Utilities/IntersectionObserver";

function isOdd(num) {
  return num % 2;
}

function Properties(props) {
  const width = useWindowWidth(),
    { inView } = useContext(IntersectionContext);

  const transition = {
    duration: 0.8,
    delay: 1.5,
  };

  const variantsLeft = {
    hidden: {
      scale: 1,
      opacity: 0,
      transition,
      x: "-400px",
    },
    show: {
      scale: 1,
      opacity: 1,
      transition,
      x: 0,
    },
  };

  const variantsRight = {
    hiddenRight: {
      scale: 1,
      opacity: 0,
      x: "400px",
    },
    showRight: {
      scale: 1,
      opacity: 1,
      x: 0,
    },
  };

  const variantsLeftSecond = {
    hidden: {
      scale: 1,
      opacity: 0,
      x: "400px",
    },
    show: {
      scale: 1,
      opacity: 1,
      x: 0,
    },
  };

  const variantsRightSecond = {
    hiddenRight: {
      scale: 1,
      opacity: 0,
      x: "-400px",
    },
    showRight: {
      scale: 1,
      opacity: 1,
      x: 0,
    },
  };

  const PropertiesItem = props.content.map((item, index) => {
    if (isOdd(index) === 0) {
      return (
        <Container
          fluid
          className="properties__section section"
          key={index}
          id={item.propertyId}
          name={`${"#" + item.propertyId}`}
        >
          <Row noGutters>
            <Col md={6}>
              <motion.div
                initial="hidden"
                animate={inView ? "show" : "hidden"}
                exit="hidden"
                variants={variantsLeft}
              >
                <CarouselImage
                  content={item.propertyImages}
                  classes="carousel--buttons-right dots--orange carousel--buttons-low"
                />
              </motion.div>
            </Col>
            <Col md={{ span: 5, offset: 1 }}>
              <motion.div
                initial="hiddenRight"
                animate={inView ? "showRight" : "hiddenRight"}
                exit="hiddenRight"
                transition={{ delay: 0.5, duration: 0.8 }}
                variants={variantsRight}
              >
                <div
                  className={`properties__item ${"properties__item--" + index}`}
                >
                  <div className="properties__image-wrapper">
                    <img src={Icon} alt="location icon" />
                  </div>
                  <h2>{item.title}</h2>
                  <p>{item.text}</p>
                  <ButtonHref
                    title={item.button}
                    link={item.buttonLink}
                    classes={item.buttonClass}
                    propertyTitle={item.title}
                  />
                </div>
              </motion.div>
            </Col>
          </Row>
        </Container>
      );
    } else {
      return (
        <Container
          fluid
          className="properties__section section"
          key={index}
          id={item.propertyId}
          name={`${"#" + item.propertyId}`}
        >
          <Row noGutters>
            <Col md={6} className="d-flex justify-content-center">
              <motion.div
                initial="hiddenRight"
                animate={inView ? "showRight" : "hiddenRight"}
                exit="hiddenRight"
                transition={{ delay: 2, duration: 0.8 }}
                variants={variantsRightSecond}
              >
                <div
                  className={`properties__item ${"properties__item--" + index}`}
                >
                  <div className="properties__image-wrapper">
                    <img src={Icon} alt="location icon" />
                  </div>
                  <h2>{item.title}</h2>
                  <p>{item.text}</p>
                  <ButtonHref
                    title={item.button}
                    link={item.buttonLink}
                    classes={`${item.buttonClass} ${"mb-3 mb-md-0"}`}
                    propertyTitle={item.title}
                  />
                </div>
              </motion.div>
            </Col>
            <Col md={6}>
              <motion.div
                initial="hidden"
                animate={inView ? "show" : "hidden"}
                exit="hidden"
                transition={{ delay: 2.5, duration: 0.8 }}
                variants={variantsLeftSecond}
              >
                <CarouselImage
                  content={item.propertyImages}
                  classes="carousel--buttons-left carousel--buttons-color--one dots--orange carousel--buttons-low"
                />
              </motion.div>
            </Col>
          </Row>
        </Container>
      );
    }
  });
  return (
    <div className="properties">
      {width <= 768 ? null : (
        <IntersectionObserver>
          <CircleSvg top="-130px" bottom="auto" left="-151px" />
        </IntersectionObserver>
      )}
      <div className="properties__background"></div>
      {PropertiesItem}
    </div>
  );
}

export default Properties;
