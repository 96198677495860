import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function CarouselImageText(props) {

  let settings = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const carouselItems = props.content.map((item, index) => {
    return (
      <div className="carousel__image-text" key={index}>
        <img src={item.imagePath} alt={item.imageAlt} />
        <div className="carousel__box">
          <img src={item.iconPath} alt={`${item.imageAlt + " icon"}`} />
          <h3 className="my-3">{item.slideTitle}</h3>
          <p>{item.slideText}</p>
        </div>
      </div>
    )
  })

  return (
    <Slider {...settings} className={`${"carousel carousel--buttons-left"} ${props.classes}`}>
      {carouselItems}
    </Slider>
  )
}

export default CarouselImageText;
