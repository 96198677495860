import React from 'react';
import FaqData from "../../Data/FaqData.json";
import { Container } from "react-bootstrap";
import AccordionElement from "../Utilities/AccordionElement";
import ButtonPrimary from '../Utilities/Buttons/ButtonPrimary';

function Faqs() {
  const CONTENT = FaqData.content,
    ADDITIONAL = FaqData.additional,
    FAQSCONTENT = FaqData.faqs;

  return (
    <section className="faqs section">
      <div className="faqs__background"></div>
      <Container>
        <div className="text-center faqs__content">
          <h2>{CONTENT[0].title}</h2>
          <div className="accordion__wrapper text-left">
            <AccordionElement
              accordionID="faqAccordion"
              content={FAQSCONTENT}
            />
          </div>
          <div className="faqs__additional">
            <h3>{ADDITIONAL[0].title}</h3>
            <p>{ADDITIONAL[0].text}</p>
            <ButtonPrimary
              title={ADDITIONAL[0].button}
              link={"#footer"}
            />
          </div>
        </div>
      </Container>
    </section>
  )
}

export default Faqs;
