import React, { useState, useEffect } from "react";
import Logo from "../../Assets/fifthelementlogo.svg";
import Nav from "../Navigation/Nav";
import { Container, Row, Col } from "react-bootstrap";

function Header() {
  const [isFixed, setFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.scrollY;
      if (currentScroll >= 20) {
        setFixed(true);
      } else {
        setFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isFixed]);

  return (
    <div className={`header-wrapper ${isFixed ? "header-scroll" : ""}`}>
      <Container>
        <Row>
          <Col>
            <div className="header-logo">
              <img src={Logo} alt="Fifth Element Spaces" />
            </div>
          </Col>
          <Col>
            <Nav />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Header;
