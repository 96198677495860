import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import FifthElementData from "../../Data/FifthElementData.json";
import CarouselImageText from "../Utilities/Carousel/CarouselImageText";
import { motion } from "framer-motion";
import { IntersectionContext } from "../Utilities/IntersectionObserver";

function CreatingFifthElement() {
  const CONTENT = FifthElementData.content,
    SLIDECONTENT = FifthElementData.slides,
    { inView } = useContext(IntersectionContext);

  const transition = {
    duration: 0.8,
    delay: 0.3,
  };

  const variantsLeft = {
    hidden: {
      scale: 1,
      opacity: 0,
      transition,
      height: "100%",
      y: "400px",
    },
    show: {
      scale: 1,
      opacity: 1,
      transition,
      height: "100%",
      y: 0,
    },
  };
  return (
    <section className="fifth-element">
      <Container>
        <motion.div
          initial="hidden"
          animate={inView ? "show" : "hidden"}
          exit="hidden"
          variants={variantsLeft}
        >
          <div className="fifth-element__title-wrapper">
            <h2 className="text-center">{CONTENT[0].title}</h2>
          </div>
          <CarouselImageText
            content={SLIDECONTENT}
            classes="carousel--line carousel--buttons-color--two"
          />
        </motion.div>
      </Container>
    </section>
  );
}

export default CreatingFifthElement;
