import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function CarouselTestimonial(props) {

  let settings = {
    arrows: true,
    dots: true,
    infinite: false,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: true
        }
      }
    ]
  };

  const carouselItems = props.content.map((item, index) => {
    return (
      <div className="carousel__testimonials" key={index}>
        <h3 className="carousel__testimonials-quote">{item.quote}</h3>
        <p className="carousel__testimonials-person">— {item.person}</p>
      </div>
    )
  })

  return (
    <Slider {...settings} className="carousel">
      {carouselItems}
    </Slider>
  )
}

export default CarouselTestimonial;
