import React from "react";
import { Modal, Button } from "react-bootstrap";

function ModalMain(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      className={props.classes}
    >
      <Modal.Header closeButton>
        <Modal.Title><div dangerouslySetInnerHTML={{ __html: `${props.title}`}} /></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.body.map((paragraph, i) => (
          <p key={i} dangerouslySetInnerHTML={{ __html: `${paragraph}` }} />
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalMain;
