import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { Card } from "react-bootstrap";
import Chevron from "../../Assets/icons/chevron.svg";

function AccordionElement(props) {
  const ACCORDIONCARD = props.content.map((item, index) => {
    return (
      <Card key={index} className={`${"card--"+index}`}>
        <Accordion.Toggle
          as={Card.Header}
          variant="link"
          eventKey={`${props.accordionID}${index}`}
        >
          {item.question}
          <span>
            <img src={Chevron} alt="accordion chevron" />
          </span>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={`${props.accordionID}${index}`}>
          <Card.Body>
            {item.answer.map((paragraph, i) => (
              // <p key={i} className={`${"accordion__answer--"}${i}`} >{paragraph}</p>
              <p key={i} className={`${"accordion__answer--"}${i}`} dangerouslySetInnerHTML={{ __html: `${paragraph}` }} />
            ))}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  });
  return <Accordion id={props.accordionID}>{ACCORDIONCARD}</Accordion>;
}

export default AccordionElement;
