import React from "react";
// import { Form, Button } from "react-bootstrap";

function ContactForm() {
  let closestByClass = function (el, closestClass) {
    while (el.className !== closestClass) {
      el = el.parentNode;
      if (!el) {
        return null;
      }
    }
    return el;
  };

  function handleClick(e) {
    if (closestByClass(e.target, "mc-field-group")) {
      e.target.parentNode.classList.add("isClicked");
    }
  }
  function moveLabel(e) {
    if (e.target.id && e.target.value) {
      e.target.parentNode.classList.add("isClicked");
    } else {
      e.target.parentNode.classList.remove("isClicked");
    }
  }

  const styles = {
    display: {
      display: "none",
    },
    position: {
      position: "absolute",
      left: "-5000px",
    },
  };

  return (
    <div id="mc_embed_signup">
      <form
        action="https://fifthelementspaces.us17.list-manage.com/subscribe/post?u=21321e44e37e88f03453af911&amp;id=79620df974"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
        target="_blank"
        noValidate
      >
        <div id="mc_embed_signup_scroll">
          <div className="indicates-required required--text">
            <span className="asterisk">*</span> indicates required
          </div>
          <div className="mc-field-group">
            <label htmlFor="mce-FNAME" className="mce-label__NAME">
              Full name <span className="asterisk">*</span>
            </label>
            <input
              type="text"
              name="FNAME"
              className="required"
              id="mce-FNAME"
              onClick={handleClick}
              onBlur={moveLabel}
              onKeyUp={handleClick}
            />
          </div>
          <div className="mc-field-group">
            <label htmlFor="mce-EMAIL" className="mce-label__EMAIL">
              Email Address <span className="asterisk">*</span>
            </label>
            <input
              type="email"
              name="EMAIL"
              className="required email"
              id="mce-EMAIL"
              onClick={handleClick}
              onBlur={moveLabel}
              onKeyUp={handleClick}
            />
          </div>
          <div className="mc-field-group">
            <label htmlFor="mce-MMERGE2" className="mce-label__TEXT">
              Message <span className="asterisk">*</span>
            </label>
            <input
              type="text"
              name="MMERGE2"
              className="required mce-input__TEXT"
              id="mce-MMERGE2"
              onClick={handleClick}
              onBlur={moveLabel}
              onKeyUp={handleClick}
            />
          </div>
          <div
            id="mergeRow-gdpr"
            className="mergeRow gdpr-mergeRow content__gdprBlock mc-field-group"
          >
            <div className="content__gdpr">
              <label>Marketing Permissions</label>
              <p className="small mb-0">
                Please select all the ways you would like to hear from Fifth
                Element Spaces Ltd:
              </p>
              <fieldset
                className="mc_fieldset gdprRequired mc-field-group"
                name="interestgroup_field"
              >
                <label className="checkbox subfield" htmlFor="gdpr_80954">
                  <input
                    type="checkbox"
                    id="gdpr_80954"
                    name="gdpr[80954]"
                    value="Y"
                    className="av-checkbox "
                  />
                  <span>Email</span>{" "}
                </label>
              </fieldset>
              <p className="small">
                You can unsubscribe at any time by clicking the link in the
                footer of our emails.
              </p>
            </div>
          </div>
          <div id="mce-responses" className="clear">
            <div
              className="response"
              id="mce-error-response"
              style={styles.display}
            ></div>
            <div
              className="response"
              id="mce-success-response"
              style={styles.display}
            ></div>
          </div>

          <div style={styles.position} aria-hidden="true">
            <input
              type="text"
              name="b_280fcafeadb0bc02f7d812bfc_a3337076c3"
              tabIndex="-1"
              defaultValue=""
            />
          </div>
          <div className="clear">
            <input
              type="submit"
              value="Submit message"
              name="subscribe"
              id="mc-embedded-subscribe"
              className="button btn btn-primary w-100"
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default ContactForm;
