import React from "react";
import "./App.scss";
import Header from "./Components/Header/Header";
import Hero from "./Components/Hero/Hero";
import Footer from "./Components/Footer/Footer";
import Faqs from "./Components/Faqs/Faqs";
import About from "./Components/About/About";
import OurPropertiesSection from "./Components/Properties/OurPropertiesSection";
import Testimonials from "./Components/Testimonials/Testimonials";
import CreatingFifthElement from "./Components/About/CreatingFifthElement";
import { IntersectionObserver } from "./Components/Utilities/IntersectionObserver";

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <IntersectionObserver>
          <Hero />
        </IntersectionObserver>
        <OurPropertiesSection />
        <IntersectionObserver>
          <CreatingFifthElement />
        </IntersectionObserver>
        <Faqs />
        <IntersectionObserver>
          <About />
        </IntersectionObserver>
        <Testimonials />
        <Footer />
      </main>
    </div>
  );
}

export default App;
